@font-face {
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Nunito Regular'), local('Nunito-Regular'),
    url(https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap)
      format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
body {
  font-family: 'Inter', sans-serif !important;
}
.css-ahj2mt-MuiTypography-root,
.css-1uwgr7b-MuiTypography-root {
  font-family: 'Inter', sans-serif !important;
}
.css-12zzxnz-MuiGrid-root {
  margin-top: 150px !important;
  margin-bottom: 50px;
}
.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
  font-family: 'Inter', sans-serif !important;
}
.top-sub-header {
  background: #084c61;
  text-align: center;
  font-weight: 500;
  p {
    font-size: 14px;
    color: #fff;
  }
}

.main-header-container {
  img {
    width: 150px;
  }
}
.product-list-item {
  box-shadow: 0px 0px 6px rgba(79, 95, 120, 0.2) !important;
  img {
    object-fit: contain;
  }
  .MuiTypography-h5 {
    font-size: 15px;
    font-weight: bold;
    margin: 0px;
  }
  .price-div {
    display: flex;
    justify-content: space-between;
    .MuiTypography-h4 {
      font-size: 17px;
    }
    button {
      background: #084c61;
      padding: 7px 17px;
      color: #fff;
      font-weight: bold;
      font-size: 13px;
    }
  }
}
.product-list-item:hover {
  box-shadow: 0px 0px 6px rgba(79, 95, 120, 0.4) !important;
}
.shopping-cart-icon-button {
  margin-right: 10px;
}
.cart-items-list {
  list-style: none;
  padding-left: 8px;
  li {
    display: flex;
    align-items: center;
    .title {
      width: 180px;
    }
    .price {
      width: 103px;
    }
    .quantity {
      width: 70px;
    }
    .action {
      width: 50px;
    }
  }
}
.place-your-order-button {
  position: fixed !important;
  bottom: 0 !important;
  background: #831c25 !important;
  width: 100% !important;
  left: 0px !important;
  border-radius: 0px !important;
  color: #fff !important;
  span {
    margin-left: 10px;
  }
}
.more-pro-button {
  font-size: 16px !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff !important;
  background-color: #c0111b;
  padding: 15px;
  text-decoration: none;
  margin: 50px auto;
  border-radius: 5px;
}
.login-button {
  font-size: 16px !important;
  font-weight: bold;
  color: #000 !important;
  padding: 8px 50px !important;
  background: none !important;
  span {
    margin-left: 10px;
  }
}

.error {
  font-size: 13px;
  color: red;
}
.form-login-button {
  background: #e12f34 !important;
  padding: 7px 17px !important;
  color: #fff !important;
  font-weight: bold !important;
  font-size: 13px !important;
  float: right;
}
.login-form-modal-close {
  position: absolute !important;
  top: 5px;
  right: 5px;
}
.profile-list-name {
  font-size: 16px !important;
}
.full-page-loader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00000040;
  width: 100%;
  height: 100%;
  z-index: 10000;
}
.divider-2 {
  width: 100%;
  height: 1px;
  background-color: #ececec;
}

.cart-totals {
  border-radius: 15px;
  box-shadow: 3px 0px 42px rgba(0, 0, 0, 0.05);
  padding: 30px 40px;
}
.order_table_list {
  .table {
    margin-top: 34px;
    padding: 9px 11px;
    width: 100%;
    margin-bottom: 1rem;
    vertical-align: top;
    border: solid 1px #ececec;
    td {
      border-bottom: solid 1px #ececec;
      padding-bottom: 10px;
      padding-top: 6px;
    }
    h6 {
      max-width: 200px;
      margin: 0px;
      font-size: 16px;
      font-weight: 400;
    }
    h4 {
      margin: 0px;
    }
    .total-row {
      td {
        border-bottom: none !important;
        padding: 25px 0px 20px;
        h6 {
          font-size: 23px;
          font-weight: bold;
        }
        h4 {
          font-weight: bold;
        }
      }
    }
  }
}
.place-on-order-btn {
  margin-top: 30px !important;
  background: #9d252d !important;
  color: #fff !important;
  float: right !important;
  padding: 10px 51px !important;
}
.view-cart-button-top {
  margin-top: 10px !important;
  margin-bottom: 30px !important;
  margin-right: 20px !important;
  background: #9d252d !important;
  color: #fff !important;
  float: right !important;
  padding: 8px 45px !important;
}
.go-to-order-history-btn {
  margin-top: 22px !important;
  margin-bottom: 9px !important;
  background: red !important;
  color: #fff !important;
  padding: 6px 58px !important;
}
.main-banner-bg-img {
  background-image: url('/assets/banner.jpg');
  background-position: center center;
  min-height: 500px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
  @media only screen and (max-width: 767px) {
    min-height: 200px;
  }
}
.bgg-slider1 {
  background-image: url('/assets/banner1.jpg');
  background-position: center center;
  min-height: 500px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
  @media only screen and (max-width: 767px) {
    min-height: 200px;
    background-size: 100% 71%;
  }
}
.bgg-slider2 {
  background-image: url('/assets/banner2.jpg');
  background-position: center center;
  min-height: 500px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
  @media only screen and (max-width: 767px) {
    min-height: 200px;
    background-size: 100% 71%;
  }
}

.bgg-slider3 {
  background-image: url('/assets/banner3.jpg');
  background-position: center center;
  min-height: 500px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100% 100%;
  @media only screen and (max-width: 767px) {
    min-height: 200px;
    background-size: 100% 71%;
  }
}
.slick-track {
  top: 70px;
  @media only screen and (max-width: 767px) {
    top: 0px;
  }
}
.main-banner-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 450px;
  @media only screen and (max-width: 767px) {
    width: 100%;
    height: auto;
  }
  .content-box {
    width: 650px;
    @media only screen and (max-width: 767px) {
      width: 100%;
    }
    text-align: center;
    h2 {
      font-size: 36px;
      @media only screen and (max-width: 767px) {
        font-size: 20px;
      }
      color: #c0111b;
    }
    h3 {
      font-size: 16px;
      @media only screen and (max-width: 767px) {
        font-size: 14px;
      }
      font-weight: 400;
      color: #000;
    }
  }
}

.mobile-app-download-sectionbanner-bg-img {
  background-color: #efefef;
  .content-box {
    @media only screen and (max-width: 767px) {
      padding: 10px 30px 20px;
    }
    h2 {
      font-size: 36px;
      margin-top: 90px;
      @media only screen and (max-width: 767px) {
        font-size: 20px;
        margin-top: 10px;
      }
      color: #c0111b;
    }
  }
  .download-right-section-img {
    @media only screen and (max-width: 767px) {
      display: none;
    }
  }
}
.footer-section {
  padding: 0px 16px;
  background-color: #212121;
  p {
    color: #fff !important;
    font-size: 14px;
    font-weight: 300;
  }
  h2 {
    font-weight: 500;
    font-size: 19px;
    color: #878787;
  }
  .footer-link {
    padding-left: 0px;
    li {
      list-style: none;
      margin-bottom: 7px;
      a {
        color: #fff;
        text-decoration: none !important;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  .follow-links {
    padding-left: 0px;
    li {
      list-style: none;
      float: left;
      margin: 6px 12px;
    }
  }
}
.copy-right-section {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: solid 1px #ccc;
  padding-top: 22px;
  padding-bottom: 20px;
  font-size: 15px;
  color: #fff;
  @media only screen and (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  a {
    color: #e12f34;
  }
}

.sigin-account-btn {
  background: none !important;
  span {
    font-size: 15px;
    margin-left: 5px;
    color: #000;
  }
}
.profile-tabs {
  border: solid 1px rgb(231, 236, 240);
  border-radius: 0.375rem;
  button {
    display: flex !important;
    flex-direction: row !important;
    justify-content: flex-start !important;
    text-transform: initial !important;
    min-height: 52px !important;
    padding: 0px 12px !important;
    font-weight: 400;
    font-size: 15px;
    color: #000;

    .MuiSvgIcon-root {
      margin-right: 8px;
      margin-bottom: 0px !important;
    }
  }
  .Mui-selected {
    background-color: rgb(243 246 249);
    font-weight: 500;
    color: #000 !important;
  }
  .MuiTabs-indicator {
    background-color: #96262f !important;
  }
}

.profile-tab-panel {
  border: solid 1px rgb(231, 236, 240);
  border-radius: 0.375rem;
}

.order-status-bullet {
  .bullet {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 6px;
  }
}
.global-fallback-page-loader {
  position: fixed;
  background: #f4f4f4bd;
  width: 100%;
  height: 100%;
  z-index: 100000;
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
}
.admin-order-details {
  h5,
  h6,
  h4 {
    margin: 0px;
  }
}

.slick-dots {
  display: none !important;
  bottom: 14px !important;
}
.slick-arrow {
  display: none !important;
}
.slick-next {
  right: 5px !important;
}
.slick-prev {
  left: 12px !important;
  z-index: 1000;
}
.MuiDataGrid-overlayWrapper {
  height: 50px !important;
}
.top-main-menu {
  margin-top: 6px;
  margin-left: 22px;
  a {
    padding: 6px 13px;
    color: #000;
    font-weight: 400;
    text-decoration: none !important;
  }
}
.profile-ranking-box {
  display: flex;
  justify-content: space-between;
  .silver {
    background-color: #9933ff;
    color: #fff;
    padding: 2px 23px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 1px;
  }
  .diamond {
    background-color: #339933;
    color: #fff;
    padding: 2px 23px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 1px;
  }
  .platinum {
    background-color: #ffcc33;
    color: #fff;
    padding: 2px 23px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 1px;
  }
  .gold {
    background-color: #ff9900;
    color: #fff;
    padding: 2px 23px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 1px;
  }
  .ruby {
    background-color: #ff4488;
    color: #fff;
    padding: 2px 23px;
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 1px;
  }
}

.padding-sec {
  padding-top: 90px;
  padding-bottom: 50px;
}
.padd-top {
  padding-top: 50px;
}
.bgappbanner {
  background-image: url('/assets/bg.jpg');
  background-position: center center;
  min-height: 250px;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: cover;
}
.content-box span {
  color: #e23d71;
  font-weight: bold;
}

.quantity-increase-decrease {
  display: flex;
  align-items: center;
  span {
    width: 28px;
    display: block;
    text-align: center;
  }
}

.header-tool-bar {
  @media only screen and (max-width: 767px) {
    padding-right: 0px !important;
    padding-left: 8px !important;
  }
}

.slick-track {
  top: 75px !important;
}
.slick-list {
  @media only screen and (max-width: 767px) {
    height: 271px;
  }
}
// .bgg-slider1 {
//   @media only screen and (max-width: 767px) {
//     height: 150px;
//   }
// }

.content-box {
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
}

.download-right-section-img {
  @media only screen and (max-width: 767px) {
    text-align: center;
    width: 250px;
    margin: 0 auto;
  }
}

.css-1k7sk4d-MuiGrid-root {
  margin: 0 auto !important;
}
